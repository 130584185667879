import { BodyContainer, HeaderContainer, ModalHeaderNavContainer } from './styles';
import { Link, useNavigate } from 'react-router-dom';
import VestaLogoSvg from '../../assets/vesta-branco.svg';
import Fechar from '../../assets/fechar.svg';
import { ReactSVG } from 'react-svg';

const ModalHeaderNav = ({setCloseModal}) => {
    const navigate = useNavigate();

    return (
        <ModalHeaderNavContainer>
            <HeaderContainer>
                <ReactSVG 
                    className='vesta-svg'
                    onClick={() => navigate('/')} 
                    src={VestaLogoSvg} alt='logo'
                />
                <div className='menu-container' onClick={() => setCloseModal(false)}>
                    <p>FECHAR</p>
                    <ReactSVG className='fechar-svg' src={Fechar} alt='fechar'/>
                </div>
            </HeaderContainer>
            <BodyContainer>
                <div className='contato'>
                    <h1>Contato</h1>
                    <h2>Onde estamos</h2>
                    <p>Rua Jandiatuba, 630 – Conj. 430 Vila Andrade – São Paulo/SP</p>
                </div>
                <nav>
                    <Link to='/' onClick={() => setCloseModal(false)}>Home</Link>
                    <a href='/#quem-somos' onClick={() => setCloseModal(false)}>Quem Somos</a>
                    <a href='/#oque-fazemos' onClick={() => setCloseModal(false)}>O que fazemos</a>
                    <Link to='cases' onClick={() => setCloseModal(false)}>Cases</Link>
                </nav>
            </BodyContainer>
        </ModalHeaderNavContainer>
    );
};

export default ModalHeaderNav;