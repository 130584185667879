import { HeaderContainer } from './styles';
import VestaLogoSvg from '../../assets/logo-vesta-branco.svg';
// import Menu from '../../assets/menu.svg';
import { useState } from 'react';
import ModalHeaderNav from '../ModalHeaderNav';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

const Header = ({scrollToContato}) => {
    const [navModalOpened, setNavModalOpened] = useState(false);
    const navigate = useNavigate();

    return (
        <HeaderContainer>
            <ReactSVG 
                className='sit-svg'
                onClick={() => navigate('/')} 
                src={VestaLogoSvg} alt='logo'
            />
            <div className='btContato'>
                <button onClick={scrollToContato}>CONTATO</button>
            </div>
            {/* <div className='menu-container' onClick={() => setNavModalOpened(true)}>
                <p>NAVEGUE</p>
                <ReactSVG 
                    className='menu-svg'
                    src={Menu} 
                    alt='menu'
                />
            </div> */}
            {navModalOpened && <ModalHeaderNav setCloseModal={setNavModalOpened}/>}
        </HeaderContainer>
    )
}

export default Header;