import Footer from '../../components/Footer';
import Header from "../../components/Header";
import { HomePageContainer } from "./styles";
import ReactGA from 'react-ga';
import {useEffect} from 'react';
import HomeV2 from '../../components/HomeV2';

const HomePage = () => {
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname);
    },[]);

    const scrollToContato = () => {
        const contatoElement = document.getElementById('contato');
        if (contatoElement) {
            contatoElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <HomePageContainer  >
            <Header scrollToContato={scrollToContato}/>
            <HomeV2/>
            <Footer />
        </HomePageContainer>
    )
};

export default HomePage;