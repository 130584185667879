import styled from 'styled-components';

export const HomeContainer = styled.div`
    > .loading {
        background: #fff;
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    }
`;

export const QuemSomosContainer = styled.div`
    > .description-container {
        padding-top:100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        column-gap: 15%;
        background:#F75F13;
        height: 800px;

        img {
            width: 800px;
        }
    
        > h1 {

            font-size: 6em;
            font-weight: 300;
            font-style: italic;
            color: #000;
            display: flex;
            flex-direction: column;
            line-height: 70px;

            > span{
                font-size: 1em;
                color: #AD1D22;
            }
        }
    
        > p {
            color: #000;

            > b{
                font-size: 1em;
                color: #000;
            }
        }
    
    }
    .logo{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;

        h1{
            font-size: 33px;
            font-family: "Poppins", sans-serif;
            color:#fff;
                
        }
        svg{
            width:80px;
            cursor:pointer;
        }

    }

    
    .arrow-container {
        display: flex;
        align-items: end;
        height: 35%;
        cursor: pointer;
    }

    > p {
        color: #000;
        text-align: center;
        font-style: italic;
        margin: 40px 0 80px;

        > b{
            color: #000;    
        }
    }

   
    @media screen and (max-width: 1000px) {
        > .description-container {
            .logo{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;

        img{
            width:600px;

        }

        h1{
            font-size: 25px;
            font-family: "Poppins", sans-serif;
                
        }
      

                }
        }
}

    @media screen and (max-width: 1070px) {
        > .description-container {
            svg {
                width: 300px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        > .description-container {
            column-gap: 5%;
            padding: 50px 15px;
    
            svg {
                width:auto;
                height: 60px;
            }

            > h1 {
                font-size: 5em;
                line-height: 30px;
            }
    
            > h2 {
                font-size: 3em;
                line-height: 18px;
            }
        }

        > p {
            margin: 10px 0 30px;
        }

    }

    @media screen and (max-width: 634px) {
        > .description-container {
            img{
                width: 450px;
            }
        }
    }

    @media screen and (max-width: 634px) {

        > .description-container {
            height: 600px;
            padding: 50px 15px 0;

            svg{
                    height: 30px;
                }   
            >.logo {
                img{
                    width: 300px;
                }   
                h1{
                    font-size: 15px;
                }
  
        }

        }
    }

    @media screen and (max-width: 390px) {
        > .description-container {
            height: 400px;
            padding: 50px 15px 0;

            svg{
                    height: 20px;
                }   
            >.logo {
                img{
                    width: 200px;
                }   
                h1{
                    font-size: 10px;
                }

        }

        }
        }
`;

export const OqueFazemosContainer = styled.div`
     display: flex;
     align-items: center;
     justify-content: center;

     img{
        width: 100%;
        padding-top: 120px;
     }

     >.divLeft, .divRight{
        width: 50%;
        display: flex;
        justify-content: center;
     }
  
     >.divRight {
        display: flex;
        flex-direction: column;

    >.box{
        width:600px;
    .tech {
        display: flex;
        align-items: center;
        gap:20px;
        margin-bottom: 20px;
        
        h2{
        color:#F75F13;
        font-size: 2.5em;
        font-weight: bold;
        
     }
     >div{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F75F13;
        height: 98px;
        border-radius: 50px;
        width: 180px;

        h3{
            font-size: 2.5em;
            font-weight: 300;
            color:#fff;
        }
     }
    }
    }
     p{
        font-size: 1.2em;
        font-weight: 500;
        color:#000;
     }
    }

    .conheca-empresa{
        width: 100%;
        display: flex;
        justify-content: end;

        >p{
            font-size:1em;
            font-weight: 700;
            color:#fff;
        }
        >button{
        margin-top:20px;
        display: flex;
        background: rgb(133, 18, 11);
        padding: 10px;
        border-radius: 35px;
        justify-content: space-around;
        align-items: center;
        border:none;
        gap:30px;
        color:#fff;

        >p{
            background:#fff;
            border-radius:50px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding:5px;
        }

            svg{
                width: 30px;
            }
        }
    }

    > .itens-container {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10%;
        width: 70%;
        margin: 0 auto;

        > .item {
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            > h2 {
                font-size: 2em;
                font-weight: 500;
                font-style: italic;
                color: #000;
            }
        
            > div {
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 10px;
            
                svg {
                    width: 60px;
                    path, polygon, rect, circle, ellipse {
                        fill: #FFC40D
                    } 
                }
            
                > p {
                    color: #000;
                }
            
                > p > a,
                > p > a:link,
                > p > a:active,
                > p > a:visited {
                    color: #000;
                }
            }
        }
    }


    @media screen and (max-width: 1250px) {
        display:flex;
        flex-direction: column-reverse;

        >.divRight{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 100px;

            >.box{
            width: 90%;

            >.tech{
                display: flex;
                justify-content: center;
                
            }
        }

        }        
        >.divLeft{
            width: 100%;
            img{
               padding-top: 20px  
            }

        }

        @media screen and (max-width: 780px) {
        >.divRight >.box >.tech{
          
                       
            div{ 
            

                height: 100%;
                padding: 10px;
            }
        
        }
    }

        
}


`;

export const CasesContainer = styled.div`
    display: flex;
     align-items: center;
     justify-content: center;
     margin: 60px 0 60px 0;
     /* height:100vh; */

     img{
        width:100%;
     }

     >.divLeft, .divRight{
        width: 50%;
        display: flex;
        justify-content: center;
     }
     .divRight >.semFundoMobile{
            display: none;
     }
     >.divLeft {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .box{
        display: flex;
        flex-direction: column;
        align-items: start;

        }
    .tech {
        display: flex;
        align-items: center;
        gap:20px;
        margin-bottom: 20px;
        width: 100%;
        padding-left: 18%;
        justify-content: start;
        
        h2{
        color:#F75F13;
        font-size: 2.5em;
        font-weight: bold;
        
     }
     >div{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F75F13;
        height: 85px;
        border-radius: 50px;
        width: 300px;

        h3{
            font-size: 2.5em;
            font-weight: 300;
            color:#fff;
        }
     }
    }
    ul{
        list-style-type: none;
        width: 100%;

    }
     li{
        font-size: 1.5em;
        width:65%;
        font-weight: 700;
        color:#000;
        padding-left:20%;
        display:flex;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #F75F13;
     }

    ul li:before {
        content: "•"; 
        color: #F7BE1C; 
        font-size: 50px; 
        margin-right: 8px;
    }
    }

    .boxSaiba-mais{
        margin-top:30px;
        width: 100%;
        padding-left: 20%;

    .saiba-mais{
        width:185px;
        margin-top:20px;
        display: flex;
        background: rgb(133, 18, 11);
        padding:5px 0 5px 0;
        border-radius: 35px;
        justify-content: space-around;
        align-items: center;
        color:#fff;
    

        >button{
            font-size:1em;
            font-weight: 700;
            color:#fff;
            border:none;
            
        }
        >p{
            border:none;
            background:#FFFFFF;
            padding:8px;
            border-radius:50%;
            color:#fff;

            svg{
                width: 30px;
            }
        }
    }
    }

    @media screen and (max-width: 1250px) {
        flex-direction: column;

        >.divLeft{
            width: 100%;
        }
        >.divRight{
            width: 100%;
            .fundoLaranja{
                display: none;
            }
            .semFundoMobile{
                display: block;
            }
        }

    }

    @media screen and (max-width: 780px) {
        >.divLeft >.tech{
            padding-left: 5px;     
            display: flex;
            align-items: center;
            justify-content: center;       
            >div{
                margin-right:10px;
                height: 100%;
                padding: 10px;
            }
        
        }
    }

    
`;

export const ClientesContainer = styled.div`
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
    column-gap: 10%;

    > div > div > svg {
        height: 200px;
    }

    > h1 {
        display: flex;
        flex-direction: column;
        font-weight: 300;
        font-size: 3em;

        > b {
            color: #FFC40D;
            font-weight: 300;
            font-size: 1em;
        }
    }

    > .carousel {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        width: 440px;
        height: 200px;

        > .logos-container {
            overflow: hidden;
            width: 100%;
            height: 150px;
        
        

                > div {
                    width: 120px;
                    margin: 0 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    > img {
                        width: 100%;
                    }
                }
            
            }
        }
    
        > .buttons-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            column-gap: 4px;
    
            width: 100%;
        }
    
        > .buttons-container > div {
            width: 16px;
            height: 16px;
            border-radius: 100%;
            cursor: pointer;
        }
    
`;
export const EmConstrucaoConatiner = styled.div`
        width: 100%;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img{
            width: 100%;
        }

    >.manutencao{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        padding:2% 0 2% 0;

    .left{
        width: 50%;
        display: flex;
        justify-content: end;

        >img{
            width:80%;
        }
    }
    
    .right{
        width: 50%;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        h2{
            color:#85120B;
            font-size:3rem;
        }
        p{
            color:#000;
            font-size:1.5rem;
            text-align:center;

        }
    }
    }

    @media screen and (max-width: 1500px) {
        >.manutencao >.right{
        
        h2{
            color:#85120B;
            font-size:1.5rem;
        }
        p{
            color:#000;
            font-size:1rem;
            text-align:center;

        }
    }
    }

    @media screen and (max-width: 830px) {
        >.manutencao {
            flex-direction: column-reverse;

        >.left{
            justify-content: center;
            width: 100%;
        }
        
        >.right{
            width: 100%;
        
        h2{
            color:#85120B;
            font-size:2rem;
        }
        p{
            color:#000;
            font-size:1.8rem;
            text-align:center;

        }
    }
    }
}

`
export const ContatoContainer= styled.div`
        background:#F75F13;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 80px 0 80px 0;

        img{
            width: 100%;
        }

    >.boxContato{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
   

    .left{
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        >div{
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 75%;

            h2{
                margin-bottom: 20px;
                font-size: 2.5rem;
                color:#fff;
            }

            input, textarea{
                border:none;
                padding: 15px;
                width:100%;
                border-radius: 8px;
                outline: none;
                color: #333;
            }
    
            .button{
                display: flex;
                justify-content: start;
                gap: 10px;
                
                button{
                    border:none;
                    display: flex;
                    padding: 10px;
                    background:#85120B;
                    border-radius:30px;
                    width:180px;
                    justify-content: space-around;
                    align-items: center;
                    font-weight: 600;
                    color:#fff;

                    p{
                        background:#fff;
                        border-radius:50px;
                        padding:10px;
                        display: flex;
                        align-items: center;


                    }
                }
            
            }
        }
    }
    
    .right{
        width: 50%;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        h2{
            color:#85120B;
            font-size:3rem;
        }
        p{
            color:#000;
            font-size:1.5rem;
            text-align:center;

        }
    }
    }

    @media screen and (max-width: 1200px) {

    >.boxContato{
    .left{
        >div{
            width: 90%;


            h2{
                margin-bottom: 20px;
                font-size: 2rem;
            }

            input, textarea{
                padding: 8px;
            }
        }
    }
    
    .right{
        width: 60%;
    }
    }

    }

    @media screen and (max-width: 1000px) {
        >.boxContato{

            flex-direction: column;
        .left{
            margin:80px 0 80px 0;
            width: 100%;
            >div{
                width: 90%;


                h2{
                    margin-bottom: 20px;
                    font-size: 2rem;
                }

                input, textarea{
                    padding: 8px;
                }
            }
        }

        .right{
            margin:80px 0 80px 0;
            width: 100%;
        }
        }

        }

        @media screen and (max-width:600px) {
            padding:0;


        }

`