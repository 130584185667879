import styled from 'styled-components';

export const HeaderContainer = styled.div`
    height: 100px;
    width: 100%;
    background-color: #F75F13;

    position: fixed;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;

    > .sit-svg {
        width: 120px;
        height: 60%;
        cursor: pointer;
        > div {
            width: 100%;
            height: 100%;
            > svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    >.btContato {
        button{
            background:#85120B;
            color:#FFFFFF;
            border:none;
            padding:10px;
            width:150px;
            font-weight:550;
            border-radius:20px;
            font-family: "Poppins", sans-serif;
          
        }
    }

    > .menu-container {
        display: flex;
        align-items: center;
        column-gap: 20px;
        height: 100%;
        cursor: pointer;
        
        > .menu-svg {
            height: 50%;
            > div {
                height: 100%;
                > svg {
                    height: 100%;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        height: 40px;

        > .menu-container {
            column-gap: 10px;
            > p {
                font-size: 1.5em;
            }
        }
    }
`;