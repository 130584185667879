import styled from 'styled-components';

export const FooterContainer = styled.div`
    width: 100%;
    background-color: #000;

    > .body {
        display: flex;
        justify-content: space-around;
        align-items: center;

        height: 100%;
        padding: 70px 0;

        > .left {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
        
            > p {
                font-size: 1.5em;
                color:#fff;
            } 
            svg{
                cursor: pointer;
            }   
        }
    
        > .right {
            display: flex;
            flex-direction: column;
            row-gap: 5px;
            text-align: left;

            > h1 {
                color: #000;
                font-weight: 100;
                font-size: 5em;
                line-height: 26px;
                font-style: italic;
            }
        
            > h2 {
                color: #AD1D22;
                font-weight: 300;
                font-size: 3em;
                font-style: italic;
            }
        
            > p {
                padding: 10px;
                width: 280px;
                color: #000;
            }
        
            > div {
                display: flex;
                column-gap: 15px;
                padding: 10px;

                > a {
                    width: 50px;

                    svg {
                        width: 100%;
                        cursor: pointer;

                        :hover {
                            path, polygon, rect, circle, ellipse {
                                fill: #EFBF2D;
                            }  
                        }
                    }
                }
            
            }
        
        }
    }

    > .footer {
        display: flex;
        align-items: center;
        height: 60px;
        padding: 0 5%;

        background-color: #000;

        > .sit-logo {
            height: 60%;
            > div {
                height: 100%; 
                > svg {
                    height: 100%;
                }
            }
        }

        > p {
            margin: 0 auto;
        }
    }

    
    @media screen and (max-width: 1350px) {
        > .body {

            padding: 70px;
        }
    }

    @media screen and (max-width: 1000px) {
        > .body {
            flex-direction: column-reverse;
            padding:30px;
        }
        > .body > .left {
           width: 100%;

           svg{
            width: 40px;
           }
        }
        
        > .body > .right {
            width: 100%;
            display: flex;
            justify-content: start;

            img{
                margin-bottom:20px;
                max-width: 40%;
            }
        }

    }

    @media screen and (max-width: 768px) {

        > .body > .left {
           width: 100%;

           svg{
            width: 30px;
           }
        }

    }
`;