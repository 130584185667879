import { FooterContainer } from './styles';
import vesta from '../../assets/vesta-transparente-footer.png';
import React from 'react';
import Linkedin from '../../assets/linkedin (1).svg';
import { ReactSVG } from 'react-svg';

const Footer = () => {
    const abrirLinkedin = () => {
        window.open('https://www.linkedin.com/company/vestaoutsourcing/about/', '_blank'); // Abre o LinkedIn em uma nova aba
      };

    return (
        <FooterContainer>
            <div className='body'>
                <div className='left'>
                    <p>Rua Jandiatuba, 630 - Conjunto 621, Bloco B, Vila Andrade - São Paulo/SP </p>
                     <p>{"(11)"} 99774-5268 | marcelo.guaycuru@vesta.com.br</p> 
                     <ReactSVG onClick={abrirLinkedin} src={Linkedin}/>
                </div>
                <div className='right'>
                    <img src={vesta} alt='logo'/>
                </div>
            </div>
     
        </FooterContainer>
    );
};

export default Footer;